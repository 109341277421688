export default function modalLogin(){
    const loginSection = document.querySelector('.login');
    const cadastroSection = document.querySelector('.cadastre');
    const showCadastroLink = document.getElementById('showCadastro');
    const showLoginLink = document.getElementById('showLogin');

    showCadastroLink.addEventListener('click', function(e) {
        e.preventDefault();
        loginSection.classList.add('hidden');
        cadastroSection.classList.remove('hidden');
    });

    showLoginLink.addEventListener('click', function(e) {
        e.preventDefault();
        cadastroSection.classList.add('hidden');
        loginSection.classList.remove('hidden');
    }); 
}