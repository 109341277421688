export default function fancyBox(){
    if(document.querySelector("[data-fancybox='gallery']")){
        Fancybox.bind("[data-fancybox='gallery']", {
            Thumbs: {
                type: "classic",
            },
        });

        Fancybox.bind("[data-fancybox='gallery-mobile']", {
            Thumbs: {
                type: "classic",
            },
        }); 
    } 
    Fancybox.bind("[data-fancybox='video-home']", {
        Thumbs: {
            type: "classic",
        },
    });  
}
