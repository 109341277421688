import { Swiper, Navigation, Pagination } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    var swiper = new Swiper('.gallery-modal-slider', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });

    // componente de banner padrão
    let bannerDefault = new Swiper(".banner__default", {
        watchOverflow: true,
        navigation: {
            nextEl: '.banner-button-next',
            prevEl: '.banner-button-prev',
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let videoSwiper = new Swiper(".video__slider", {
        watchOverflow: true,
        navigation: {
            nextEl: '.swiper-button-next.video-homen',
            prevEl: '.swiper-button-prev.video-homep',
        },
        pagination: {
            el: ".banner-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    let swiperDiversos = new Swiper('.diversos-slider', {
        slidesPerView: 3,
        spaceBetween: 20,
        loop: false,
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
        pagination: {
          el: '.swiper-pagination.diversos',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next.diversosn',
          prevEl: '.swiper-button-prev.diversosp',
        },
    });
};
